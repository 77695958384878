import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import Notifications from "vue-notification";

Vue.use(Notifications);
Vue.config.productionTip = false;
window.axios = require("axios");
window.web3 = require("web3");
window.Buffer = require("buffer/").Buffer;
Vue.mixin({
  data: function () {
    return {
      // https://vap.smartpayd.app/
      // http://localhost:8000/
      apiAddress: "https://vap.smartpayd.app/",
    };
  },
});
new Vue({
  data: {
    walletAddress: "",
    wcon: "",
  },
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
