<template>
  <v-container>
    <v-dialog
      v-model="list_application_dialog"
      transition="dialog-top-transition"
      fullscreen
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar color="primary" dark
            ><v-icon class="mr-2">mdi-information</v-icon> Submit Your
            Project</v-toolbar
          >
          <v-card-text>
            <div class="pa-12">
              <v-form
                ref="listApplicationForm"
                v-model="list_application_data.valid"
                lazy-validation
              >
                <v-text-field
                  v-model="list_application_data.name"
                  :counter="40"
                  :rules="list_application_data.nameRules"
                  label="Name"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="list_application_data.email"
                  :rules="list_application_data.emailRules"
                  label="E-mail"
                  required
                ></v-text-field>

                <v-select
                  v-model="list_application_data.select"
                  :items="list_application_data.items"
                  :rules="[(v) => !!v || 'Item is required']"
                  label="Your Application Section"
                  required
                ></v-select>

                <v-text-field
                  v-model="list_application_data.url"
                  :rules="list_application_data.urlRules"
                  label="Project URL"
                  required
                ></v-text-field>
                <v-btn
                  :disabled="!list_application_data.valid"
                  color="primary"
                  class="ml-auto mr-auto mt-2 d-block"
                  @click="validateListApplicationForm"
                >
                  Submit Your Request
                </v-btn>
              </v-form>
            </div>
            <div class="float-end">
              <v-btn color="primary" outlined text @click="dialog.value = false"
                >Close</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logo_v.png')"
          class="my-3"
          contain
          height="200"
        />
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col cols="12" class="mb-1">
        <h1 class="display-2 font-weight-bold mb-3">SmartPay</h1>

        <p class="subheading font-weight-regular">
          Smart Pay is a smart platform based on payment with digital currency,
          which provides all the services of a payment gateway as a smart
          contract in a secure platform.
          <br />
        </p>
        <p>
          If You need any support or having issue please be in touch with
          <a href="mailto:support@smartpayd.com">Support Team.</a>
        </p>
      </v-col>
    </v-row>
    <v-alert v-if="showAlert" color="warning" type="warning" elevation="3">
      <p style="line-height: 25px">
        <b>Warning</b>
        <br />
        BUSD based contracts changed to
        <a
          style="color: white !important"
          target="_blank"
          href="https://bscscan.com/token/0x55d398326f99059fF775485246999027B3197955"
          >Binance-Peg BSC-USD</a
        >
        token.
        <br />
        Please pay with Binance-Peg BSC-USD (BSC-USD) token for payment.
        <br /><b>SmartPay Support Team</b>
      </p>
    </v-alert>
    <v-alert
      v-if="deferredPrompt"
      border="left"
      colored-border
      type="warning"
      elevation="2"
    >
      Please install our application for more features and notifications.
      <div>
        <v-btn @click="installPWA" color="primary" outlined class="mt-4"
          ><v-icon class="mr-2">mdi-download-outline</v-icon
          ><span>Install SMARTPAY</span></v-btn
        >
      </div>
    </v-alert>
    <v-row class="mt-4 mb-4 text-center">
      <v-col md="3">
        <a href="#">
          <img
            width="64"
            height="64"
            :src="require('../assets/trade.png')"
            alt=""
          />
          <span class="d-block">TanacTrade</span>
        </a>
      </v-col>
      <v-col md="3">
        <a href="#">
          <img
            width="64"
            height="64"
            :src="require('../assets/ticket.png')"
            alt=""
          />
          <span class="d-block">LynerTicket</span>
        </a>
      </v-col>
      <v-col md="3">
        <a href="#">
          <img
            width="64"
            height="64"
            :src="require('../assets/gem.png')"
            alt=""
          />
          <span class="d-block">NiwaGem</span>
        </a>
      </v-col>
      <v-col md="3">
        <a href="#">
          <img
            width="64"
            height="64"
            :src="require('../assets/digi_2.png')"
            alt=""
          />
          <span class="d-block">DigiCollege</span>
        </a>
      </v-col>
    </v-row>
    <!--  <p>
          Add button to trigger modal here for contact for for joint venture
        </p> -->
    <v-row class="text-center">
      <v-col cols="12">
        <h1 class="text-center font-weight-bold headline">Partnership</h1>
        <p class="pa-2">
          Smartpay cooperates with online service developers to sell their
          services and products through a digital currency-based smart payment
          gateway and increase their user community with a referral system. If
          you are an online service developer, you can use the smart payment
          gateway anywhere in the world.
          <br />If you want to work with SmartPay, please be in touch with us.
        </p>
        <v-btn @click="list_application_dialog = true" outlined color="primary">
          Submit Your Request
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-4 mb-4 text-center">
      <v-col cols="12">
        <h1 class="text-center font-weight-bold headline">Certificates</h1>
      </v-col>
      <v-col md="4">
        <a href="#">
          <img
            width="64"
            height="64"
            :src="require('../assets/logo.png')"
            alt=""
          />
        </a>
      </v-col>
      <v-col md="4">
        <a href="#">
          <img
            width="64"
            height="64"
            :src="require('../assets/logo.png')"
            alt=""
          />
        </a>
      </v-col>
      <v-col md="4">
        <a href="#">
          <img
            width="64"
            height="64"
            :src="require('../assets/logo.png')"
            alt=""
          />
        </a>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col cols="12">
        <h1 class="font-weight-regular mb-3">Based on</h1>
        <div>
          <img
            width="64"
            height="64"
            class="mr-2"
            :src="require('../assets/bsc.png')"
            alt=""
          />
          <img
            width="64"
            height="64"
            class="mr-l"
            :src="require('../assets/binance-logo.png')"
            alt=""
          />
        </div>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col class="mb-5" cols="12">
        <h1 class="font-weight-regular mb-3">Links</h1>

        <v-row class="justify-center mt-2">
          <v-btn
            v-for="(eco, i) in ecosystem"
            :key="i"
            :to="eco.href"
            depressed
            color="primary"
            class="mx-3 mt-2"
          >
            {{ eco.text }}
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HelloWorld",
  created: function () {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });
    var dt = new Date();
    const m = dt.getUTCMonth() + 1;
    const y = dt.getUTCFullYear();
    if ((y == 2023 || y == 2024) && (m == 12 || m == 1 || m == 2)) {
      this.showAlert = true;
    }
  },
  data: () => ({
    showAlert: false,
    list_application_data: {
      valid: true,
      name: "",
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 40) || "Name must be less than 40 characters",
      ],
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      url: "",
      urlRules: [
        (v) => !!v || "Project URL is required",
        (v) =>
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(
            v
          ) || "URL must be valid",
      ],
      select: null,
      items: ["Fintech", "AI", "E-Commerce", "Blockchain"],
    },
    list_application_dialog: false,
    colors: [
      "orange darken-1",
      "orange darken-2",
      "orange darken-3",
      "orange lighten-1",
      "orange",
    ],
    cycle: true,
    deferredPrompt: null,
    slides: ["First", "Second", "Third", "Fourth", "Fifth"],
    ecosystem: [
      {
        text: "Sign up",
        href: "/payment",
      },
      {
        text: "Rules",
        href: "/rules",
      },
      {
        text: "About",
        href: "/about",
      },
      {
        text: "FAQ",
        href: "/faq",
      },
    ],
  }),
  methods: {
    validateListApplicationForm() {
      this.$refs.listApplicationForm.validate();
    },
    async installPWA() {
      if (this.deferredPrompt) {
        this.deferredPrompt.prompt();
      }
    },
  },
};
</script>
