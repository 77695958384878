<template>
  <v-app>
    <v-dialog
      v-model="connect_wallet_dialog"
      transition="dialog-top-transition"
      max-width="600"
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar color="primary" dark
            ><v-icon class="mr-2">mdi-wallet</v-icon> Wallet Connection
            Method</v-toolbar
          >
          <v-card-text>
            <div class="pa-12">
              <v-row class="text-center">
                <v-col cols="6">
                  <v-img
                    @click="syncWalletV2(false)"
                    :src="require('../src/assets/walletconnect.png')"
                  />
                  <div class="mt-2 header">WalletConnect</div>
                </v-col>
                <v-col cols="6">
                  <v-img
                    @click="connectToMetaMask"
                    :src="require('../src/assets/metamask.png')"
                  />
                  <div class="mt-2 header">MetaMask</div>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="dialog.value = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-dialog
      persistent
      v-model="notificationListDialog"
      transition="dialog-top-transition"
      max-width="600"
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar color="blue darken-1" dark
            ><v-icon class="mr-2">mdi-bell</v-icon> Notification List
            <v-spacer></v-spacer
            ><v-btn outlined @click="dialog.value = false"
              >Close</v-btn
            ></v-toolbar
          >
          <v-card-text>
            <div class="pa-1">
              <v-row>
                <v-col cols="12">
                  <v-tabs
                    color="blue lighten-2"
                    grow
                    v-model="notificationTab"
                    dark
                    icons-and-text
                  >
                    <v-tabs-slider></v-tabs-slider>

                    <v-tab href="#tab-1">
                      <v-badge
                        color="red"
                        offset-x="10"
                        offset-y="-5"
                        :content="notifications.public.length.toString()"
                      >
                        General
                      </v-badge>
                      <v-icon>mdi-web</v-icon>
                    </v-tab>

                    <v-tab href="#tab-2">
                      <v-badge
                        color="red"
                        offset-x="10"
                        offset-y="-5"
                        :content="notifications.private.length.toString()"
                      >
                        Private
                      </v-badge>
                      <v-icon>mdi-message</v-icon>
                    </v-tab>
                  </v-tabs>

                  <v-tabs-items v-model="notificationTab">
                    <v-tab-item value="tab-1">
                      <v-card flat>
                        <v-card-text>
                          <v-list>
                            <v-subheader>SYSTEM MESSAGES</v-subheader>
                            <v-alert
                              v-if="notifications.public.length == 0"
                              type="success"
                              color="blue"
                              >No new notification.</v-alert
                            >
                            <v-list-item
                              v-for="(n, i) in notifications.public"
                              :key="i"
                            >
                              <v-list-item-icon>
                                <v-icon color="primary"> mdi-bell-ring </v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>{{
                                  n["title"]
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                  class="text-wrap mt-2 mb-2"
                                  >Date :
                                  {{
                                    new Date(n["created_at"])
                                      .toISOString()
                                      .slice(0, 16)
                                      .replace("T", " ")
                                  }}</v-list-item-subtitle
                                >
                                <v-list-item-subtitle class="text-wrap mb-2"
                                  >Type :
                                  <v-btn x-small depressed color="primary">
                                    General
                                  </v-btn></v-list-item-subtitle
                                >
                                <v-list-item-subtitle class="text-wrap">{{
                                  n["content"]
                                }}</v-list-item-subtitle>
                                <v-divider class="mt-4"></v-divider>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item value="tab-2">
                      <v-card flat>
                        <v-card-text>
                          <v-list>
                            <v-subheader>PRIVATE MESSAGES</v-subheader>
                            <v-alert
                              v-if="notifications.private.length == 0"
                              type="success"
                              color="blue"
                              >No new notification.</v-alert
                            >
                            <v-list-item
                              v-for="(n, i) in notifications.private"
                              :key="i"
                            >
                              <v-list-item-icon>
                                <v-icon color="error"> mdi-email </v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>{{
                                  n["title"]
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                  class="text-wrap mt-2 mb-2"
                                  >Date :
                                  {{
                                    new Date(n["created_at"])
                                      .toISOString()
                                      .slice(0, 16)
                                      .replace("T", " ")
                                  }}</v-list-item-subtitle
                                >
                                <v-list-item-subtitle class="text-wrap mb-2"
                                  >Type :
                                  <v-btn x-small depressed color="error">
                                    Private
                                  </v-btn></v-list-item-subtitle
                                >
                                <v-list-item-subtitle class="text-wrap">{{
                                  n["content"]
                                }}</v-list-item-subtitle>
                                <v-divider class="mt-4"></v-divider>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
    <v-dialog v-model="walletDisconnectionDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Wallet Disconnect </v-card-title>
        <v-card-text
          >Are you sure you want to disconnect this wallet?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error darken-1" text @click="disconnectWallet()">
            Yes
          </v-btn>
          <v-btn
            color="success darken-1"
            text
            @click="walletDisconnectionDialog = false"
          >
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-app-bar app color="dark" class="appBarStyle" dark>
      <div class="d-flex align-center">
        <v-btn
          v-if="!walletAddress && !walletConnectBtnShow"
          @click="connect_wallet_dialog = true"
          dark
          outlined
          class="mr-2 ml-2 btn-style"
        >
          No wallet connected!</v-btn
        >
        <v-btn
          v-if="walletAddress"
          color="primary"
          @click="walletDisconnectionDialog = true"
          outlined
          class="mr-2 ml-2 hidden-sm-and-down"
          >Connected to : {{ walletAddress }}</v-btn
        >
        <v-btn
          @click="copyReferralCode"
          v-if="walletAddress && this.$router.currentRoute.path == '/dashboard'"
          color="primary"
          outlined
          class="mr-1 ml-1"
          >Ref. Code</v-btn
        >
        <v-btn
          v-if="walletAddress"
          color="success"
          outlined
          @click="walletDisconnectionDialog = true"
          class="mr-1 ml-1 hidden-md-and-up"
          ><v-icon class="mdi mdi-connection"></v-icon
        ></v-btn>
      </div>

      <v-spacer></v-spacer>
      <v-btn
        @click="showNotificationList"
        v-if="walletAddress && this.$router.currentRoute.path == '/dashboard'"
        color="error"
        outlined
        class="mr-1 ml-1"
      >
        <v-badge
          color="error"
          offset-x="-30"
          offset-y="9"
          bordered
          :content="
            (
              notifications.public.length + notifications.private.length
            ).toString()
          "
        ></v-badge
        ><v-icon class="mdi mdi-bell-outline"></v-icon
      ></v-btn>
      <a href="/">
        <v-img
          style="width: 64px !important"
          :src="require('./assets/logo.png')"
          contain
          height="40"
        />
      </a>
    </v-app-bar>
    <v-main>
      <notifications group="alert" />
      <notifications position="top left" :max="2" group="info" />
      <router-view :key="refreshKey" />
    </v-main>
    <v-footer padless>
      <v-col class="text-center" cols="12">
        {{ new Date().getFullYear() }} — <strong>SmartPayd.app</strong>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import { EthereumProvider } from "@walletconnect/ethereum-provider";
import Vue from "vue";

export default {
  name: "App",
  created() {
    document.title = "SmartPay - A step closer to Decentralized world";
  },
  data: () => ({
    //
    refreshKey: 0,
    notificationTab: null,
    walletConnectBtnShow: false,
    walletConnectToast: null,
    walletAddress: "",
    notificationListDialog: false,
    walletAccounts: [],
    wcon: null,
    notifications: {
      private: [],
      public: [],
    },
    walletDisconnectionDialog: false,
    walletConnectToastSuccessed: false,
    walletConnectToastFailed: false,

    // connect wallet props
    connect_wallet_dialog: false,
    web3Modal: null,
    signClient: null,
    session: null,
    accounts: null,
    provider: null,
  }),
  methods: {
    async disconnectWallet(isRemote = false) {
      var doc = this;
      if (!isRemote) {
        await doc.provider.signer.client.disconnect({
          topic: doc.provider.signer.session.topic,
          message: "User disconnected",
          code: 6000,
        });
      }
      this.walletAddress = null;
      this.wcon = null;
      this.walletDisconnectionDialog = false;
      this.$root.walletAddress = this.walletAddress;
      this.$root.con = this.wcon;
      this.$root.chain = null;
      if (this.$route.name == "dashboard") {
        this.$router.push({ name: "home" });
      }
    },
    async subscribeToEvents() {
      if (!this.provider)
        throw Error("Unable to subscribe to events. Client does not exist.");
      try {
        var doc = this;
        this.provider.on("chainChanged", function () {});
        // accounts changed
        this.provider.on("accountsChanged", function () {});
        // session established
        this.provider.on("connect", function () {
          doc.$notify({
            group: "alert",
            type: "success",
            title: "Connection Successfull",
            text: "Wallet Connected Successfully.",
          });
        });
        // session event - chainChanged/accountsChanged/custom events
        this.provider.on("session_event", function () {});
        // connection uri
        this.provider.on("display_uri", function () {});
        // session disconnect
        this.provider.on("disconnect", function () {
          doc.disconnectWallet(true);
          doc.$notify({
            group: "alert",
            type: "warning",
            title: "Wallet Disconnected",
            text: "Wallet Disconnected Remotely.",
          });
        });
      } catch (e) {
        console.log(e);
      }
    },
    async syncWalletV2(onlyCheck = false) {
      this.connect_wallet_dialog = false;
      await this.provider.connect();
      this.onSessionConnected();
    },
    async onSessionConnected() {
      try {
        this.walletAddress = this.provider.accounts[0];
        this.subscribeToEvents();
        this.updateData();
      } catch (e) {
        console.log(e);
      }
    },
    checkIfUserExist(address) {
      window.axios
        .post(`${this.apiAddress}api/user/walletConnect`, {
          walletAddress: address,
        })
        .then(
          (response) => {
            if (response.data.success) {
              this.notifications = response.data.notifications;
              this.$notify({
                group: "alert",
                type: "success",
                title: "Login Successfull",
                text: "Welcome back.",
              });
              if (this.$route.name != "dashboard") {
                this.$router.push({ name: "dashboard" });
              }
            }
          },
          (error) => {}
        );
    },
    showNotificationList() {
      this.notificationListDialog = true;
    },
    copyToClipBoard(textToCopy) {
      navigator.clipboard.writeText(textToCopy);
    },
    async copyReferralCode() {
      this.copyToClipBoard(
        "https://smartpayd.app/payment?referrer=" + this.walletAddress
      );
      this.$notify({
        group: "alert",
        type: "primary",
        title: "Operation Completed",
        text: "Referral Code Copied Successfully!",
      });
    },
    updateData() {
      this.walletAddress = this.provider.accounts[0];
      this.$root.chain = this.provider.chainId;
      this.wcon = this.provider;
      console.log("Provider", this.wcon);
      this.$root.con = this.wcon;
      this.$root.walletAddress = this.provider.accounts[0];
      this.checkIfUserExist(this.provider.accounts[0]);
    },
    connectToMetaMask() {
      this.$notify({
        group: "alert",
        type: "primary",
        title: "Connection Failed",
        text: "Metamask is currently unavailable.",
      });
    },
    async setupWC() {
      var doc = this;
      var projectId = "f8cba101cc2a079a655f75050494ca23";
      this.provider = await EthereumProvider.init({
        projectId,
        chains: [56],
        rpcMap: ["https://bsc-dataseed.binance.org"],
        methods: ["eth_sign", "eth_sendTransaction"],
        metadata: {
          name: "SmartPay",
          description:
            "SmartPay uses P2P marketing and Instant Payment which promotes the growth and development of online platforms and provides ease and speed in payment for users.",
          url: "https://smartpayd.app",
          icons: ["https://smartpayd.app/img/logo.204d7b5f.png"],
        },
        showQrModal: true,
        qrModalOptions: {
          projectId,
          chains: ["eip155:56"],
          themeMode: "dark",
          termsOfServiceUrl: "https://smartpayd.app/rules",
          explorerRecommendedWalletIds: [
            "0b415a746fb9ee99cce155c2ceca0c6f6061b1dbca2d722b3ba16381d0562150",
            "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0",
          ],
        },
      });
      const lastSession = this.provider.signer.session;
      if (lastSession) {
        this.session = lastSession;
        this.onSessionConnected();
      }
    },
  },
  async created() {
    // this.isConnected();
    await this.setupWC();
    var doc = this;
    this.$root.$on("getWalletConnection", function (cb) {
      cb(doc.wcon, doc.signClient, doc.session);
    });
  },
  async mounted() {},
};
</script>
<style>
.v-application .display-1 {
  font-family: "Lato", sans-serif !important;
}
.v-application .display-2 {
  font-family: "Lato", sans-serif !important;
}
.v-application .display-3 {
  font-family: "Lato", sans-serif !important;
}
.v-application .display-4 {
  font-family: "Lato", sans-serif !important;
}
.v-application .display-5 {
  font-family: "Lato", sans-serif !important;
}
body,
h1,
h2,
h3,
h4,
h5,
p,
span {
  font-family: "lato", sans-serif !important;
}
.v-application {
  font-family: "lato", sans-serif !important;
}
.title {
  font-family: "lato", sans-serif !important;
}
.dpx-active {
  color: "white !important;";
}
.appBarStyle {
  background-color: rgba(39, 39, 39, 0.5);
}
.btn-style {
  /** background-color: rgb(4, 127, 195) !important; */
  background: linear-gradient(90deg, #ff8803 33%, #ffffffcc 50%, #ff8803 66%)
    rebeccapurple;
  background-size: 400% 100%;
  animation: shine 3s infinite;
}

.v-parallax__content {
  background-color: rgb(1, 1, 1, 0.5);
}
.v-parallax__content * {
  text-shadow: 2px 2px 2px #0a0a0a;
}
@keyframes shine {
  0% {
    background-position: right;
  }

  /*100% {
    background-position: left; it's the default value, no need to define it
  }*/
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>